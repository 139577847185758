.registration-panel {
    padding: 10px;
}

.registration-panel-main-title {
    font-size: 18px;
}

.registration-panel-illustration-container {
    background-image: url('../../../../../images/registration_background_mobile.png');
    height: 50vh;
    background-size: 100%;
    background-position: bottom left;
    background-repeat: no-repeat;
    margin-bottom: 20px;
}

.registration-back-link-container {
    margin-top: 26px;
}

.registration-back-link-container-centered {
    text-align: center;
}

.registration-exit-link {
    padding: 20px;
}

.registration-panel-main-content {
    //Used to display the chatra chat button
    padding-bottom: 50px;
}

.registration-panel-extra {
    margin-bottom: 20px;
}

.registration-logo {
    height: 35px;
}
