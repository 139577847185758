.profile-search-alert-container {
    flex-direction: column;
    border: 1px solid $main-orange-color;
    margin-top: 14px;
    padding: 5px;
}

.profile-search-alert-actions-containers {
    border-top: 1px solid $main-gray-color;
}
