.member-box {
    margin-top: 24px;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

.member-box-infos {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member-box-lower-part {
    display: flex;
    padding-bottom: 8px;
}

.member-box-extras {
    width: 50%;
}

.member-box-picture-container {
    width: 50%;
}

.member-box-picture-container-only {
    width: 100%;
}

.member-box-extras {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
