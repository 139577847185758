a.button-submit-small-profile-subscription,
.button-submit-small-profile-subscription {
    width: 150px;
    height: 50px;
}

a.button-submit-medium-action {
    width: 200px;
    height: 40px;
    font-size: 18px;
}
