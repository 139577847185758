.company-choice-box-search {
    min-width: 250px;
    padding-left: 25px;
}

.company-choice-box-search-icon {
    left: 5px;
}

.company-choice-box-picture-container {
    width: 40%;
}

.company-choice-box-infos {
    width: 60%;
    padding-left: 7px;
    padding-right: 7px;
}

.company-choice-box-form .company-choice-box-infos {
    width: 70%;
}

.company-choice-box-infos-container {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 100%;
}

.company-choice {
    font-size: 16px;
}
